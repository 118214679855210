import { HttpClient } from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { AppConfig } from "./app.config";
import { IAppConfig } from "./shared/models/app-config.model";

export function initializeAppFactory(httpClient: HttpClient): () => Observable<any> {
  const jsonFile = `assets/config/config.${environment.name}.json`;
  return () => httpClient.get(jsonFile)
    .pipe(
       tap(response => {
        AppConfig.settings = <IAppConfig>response;
       })
    );
 }
