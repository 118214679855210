import { inject} from '@angular/core';
import { ActivatedRouteSnapshot, Router} from '@angular/router';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';


export const MobileGuard = (
  route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const deviceService = inject(DeviceDetectorService);
    const deviceInfo: DeviceInfo = deviceService.getDeviceInfo();
    if (deviceService.deviceType == "mobile") {
      const path = route?.routeConfig?.path;
      if (path) {
        router.navigateByUrl(`/mobile/${route.routeConfig.path}`);
      } else {
        // If we don't have a path go to mobile
        router.navigateByUrl(`/mobile`);
      }

      return false;
    }

    return true;
}
