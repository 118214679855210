import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { selectToken, selectRefreshToken } from './auth.selectors';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';
import { AuthActions } from './action-types';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  token?: string;
  refreshToken?: string;
  private isRefreshing = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor(private store: Store, private auth: AuthService) {
    this.store.select(selectToken).subscribe((t) => (this.token = t));
    this.store.select(selectRefreshToken).subscribe((t) => (this.refreshToken = t));
  }



  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.token) {
      // If we have a token, we set it to the header
      req = this.addTokenToRequest(req, this.token)
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401
        ) {
          return this.handle401Error(req, next);
        }

        return throwError(() => error);
      })
    )as Observable<HttpEvent<any>>;;
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string ): HttpRequest<any> {
    return request.clone({
      setHeaders: { Authorization: `Bearer ${this.token}` },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      if (this.token) {
        return this.auth.refreshToken(this.token, this.refreshToken!).pipe(
          switchMap((user) => {
            this.isRefreshing = false;
            this.store.dispatch(AuthActions.login({ user }));
            request = request.clone({
              setHeaders: { Authorization: `Bearer ${user.token}` },
            });
            return next.handle(request);
          }),
          catchError((error) => {
            this.isRefreshing = false;

            this.store.dispatch(AuthActions.logout());
            return throwError(() => error);
          }),
          finalize(() => {
            this.isRefreshing = false;
          })
        );
      }
    } else {
      this.isRefreshing = false;

      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addTokenToRequest(request, token));
        })
      );
    }

    return next.handle(request);
  }

}
