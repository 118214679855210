import { NgModule, isDevMode, APP_INITIALIZER, inject, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RecaptchaModule } from "ng-recaptcha-2";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { initializeAppFactory } from "./app.initialize.factory";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import localeEs from '@angular/common/locales/es-AR';
import { registerLocaleData } from '@angular/common';
import { JoyrideModule } from 'ngx-joyride';

registerLocaleData(localeEs, 'es');

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        StoreModule.forRoot(reducers, {
            metaReducers
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode(), connectInZone: true }),
        EffectsModule.forRoot([]),
        BrowserAnimationsModule,
        AuthModule,
        SharedModule,
        MatSnackBarModule,
        JoyrideModule.forRoot(),
        RecaptchaModule], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [HttpClient],
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'es-Ar' },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
