import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthActions } from './auth/action-types';
import { AppState } from './reducers';
import { AppConfig } from './app.config';
import { SharedActions } from './shared/state/shared-action-types';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  workInProgressFlag = AppConfig.settings?.workInProgress;
  appName = AppConfig.settings?.appName;
  companyKey = AppConfig.settings?.company.key;

  constructor(private store: Store<AppState>){
    const userProfile = localStorage.getItem('user');
    if (userProfile) {
      var user = JSON.parse(userProfile);
      this.store.dispatch(AuthActions.login({user}));
    }
    this.store.dispatch(SharedActions.setworkInProgressFlag({workInProgress: this.workInProgressFlag}));

    const companySettings = localStorage.getItem('companySettings');
    if (companySettings) {
      var setting = JSON.parse(companySettings);
      this.store.dispatch(SharedActions.loadCompanySettingsLoaded({ data: setting }));
    }


    this.changeIcon();
  }

  ngOnInit(): void {
    if (AppConfig.settings?.googleAnalyticsKey) {
      this.loadGoogleAnalytics(AppConfig.settings?.googleAnalyticsKey);
    }
  }
  title = 'gigance-ui';

  private changeIcon () {
    if (this.companyKey) {
      var link: HTMLAnchorElement  = document.querySelector("link[rel~='icon']");
      link.href =  `./assets/img/favicon-${this.companyKey}.ico`;
    }
  }

  loadGoogleAnalytics(key: string) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${key}`;
    script.async = true;
    document.head.appendChild(script);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${key}');
    `;
    document.head.appendChild(script2);
  }
}
