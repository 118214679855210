import { inject} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import { tap, zip} from 'rxjs';
import { Store } from '@ngrx/store';
import { isLoggedIn } from '../../auth/auth.selectors';
import { getCompanySettings } from '../state/shared.selectors';
import { BaseSettings } from '../models/settings/company-settings.model';


export const ModuleGuard = (
  route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const service = inject(Store);
    return zip(
      service.select(isLoggedIn),
      service.select(getCompanySettings)
    ).pipe(
      tap((values) => {
        if (!values[0] || !values[1]) {
          router.navigateByUrl('/login');
          return false;
        }

        const companySettings = values[1];
        let hasModule = true;
        if (companySettings && route.data['module']) {
          (route.data['module'] as string[]).forEach(m => {
            if (!(companySettings[m] as BaseSettings).enabled) {
              router.navigateByUrl('/login');
              hasModule = false;
              return false;
            }
            return true;
          })
        }

        return hasModule;
    }
  ));
}
